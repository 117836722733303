"use client";

import { useCallback } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { signIn } from "next-auth/react";

import { Google } from "~/assets/icons";
import { LoginForm } from "~/components/forms/login";
import { FadeInContainer } from "~/components/layout/fade-in-container";
import { Button } from "~/components/ui/button";
import { useLogin } from "~/lib/api/client/auth";

export const AuthLogin = () => {
  const router = useRouter();
  const { loginWithCredentials, error } = useLogin({
    onSuccess: useCallback(() => router.push("/"), [router]),
  });

  return (
    <div className="w-full max-w-90 flex flex-col gap-6 desktop:max-w-120">
      <FadeInContainer className="flex flex-col items-center gap-6">
        <h2 className="text-lg text-center text-grey-700 desktop:text-2xl">Log In</h2>

        <Button
          variant="primary"
          className="max-w-70 mx-auto gap-2"
          onClick={() => signIn("google", { callbackUrl: "/", redirect: false })}
        >
          <Google /> Sign in with Google
        </Button>

        <p className="text-center text-xs">- OR -</p>

        <LoginForm onSubmit={loginWithCredentials} error={error} />

        <p className="text-xs text-center">
          <Link href="/auth/forgot-password" className="text-primary-700">
            Forgot password?
          </Link>
        </p>

        <p className="text-xs text-center text-grey-700">
          Don&apos;t have an account?{" "}
          <Link href="/auth/register" className="text-primary-700">
            Sign up
          </Link>
        </p>
      </FadeInContainer>
    </div>
  );
};
