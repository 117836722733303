export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const API_KEY = process.env.NEXT_PUBLIC_API_KEY;
export const BACKEND_API_KEY = process.env.BACKEND_API_KEY;

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || "";
export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "";

export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_CLUSTER;
export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_KEY;

export const CUSTOMER_PORTAL_LINK = process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL;

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

export const PEXELS_PHOTOS_PER_PAGE = 24;

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png"];

export const CTA_MAX_LENGTH = 25;
export const TITLE_MAX_LENGTH = 40;
export const SMALLER_TEXT_MAX_LENGTH = 40;

export const HEADLINE_MAX_LENGTH = 27;
export const DESCRIPTION_MAX_LENGTH = 100;
export const PRIMARY_TEXT_MAX_LENGTH = 80;

export const CREATIVE_COST = 1;

export const MIN_SEARCH_QUERY_LENGTH = 3;
