"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { useSession } from "next-auth/react";
import { getCookies } from "cookies-next";

import { AnalyticsBrowser } from "@segment/analytics-next";

import { SEGMENT_WRITE_KEY } from "~/services/__constants";
import { TRACKING_EVENTS } from "~/services/__events";
import { getUtmParamsFromCookies } from "~/shared/utils/utils";

export const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_WRITE_KEY,
});

export default function SegmentAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { data: session, update } = useSession();

  useEffect(() => {
    analytics.page();
  }, [pathname, searchParams]);

  useEffect(() => {
    const identification = async () => {
      const userTraits = (await analytics.user()).traits();
      const userId = (await analytics.user()).id();

      const isNewUser = session?.isNewUser;
      const isUserUpdated =
        userTraits?.first_name !== session?.user.firstName || userTraits?.last_name !== session?.user.lastName;

      if ((!userId || isUserUpdated) && session) {
        const cookies = getCookies();

        await analytics.identify(session.id, {
          email: session.user.email,
          first_name: session.user.firstName,
          last_name: session.user.lastName,
          ...(isNewUser ? getUtmParamsFromCookies(cookies) : {}),
        });

        if (isNewUser) {
          await analytics.track(TRACKING_EVENTS.userSignedUp, {
            first_name: session.user.firstName,
            last_name: session.user.lastName,
            email: session.user.email,
            username: session.user.email,
          });
          await update({ ...session, isNewUser: false });
        }

        if (!isUserUpdated) {
          await analytics.track(TRACKING_EVENTS.userSignedIn, {
            username: session.user.email,
          });
        }
      } else if (userId && !session) {
        await analytics.reset();
      }
    };

    identification();
  }, [session, update]);

  return null;
}
