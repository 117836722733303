export const TRACKING_EVENTS = {
  trialStarted: "trial_started",
  paymentInfoAdded: "payment_info_added",
  brandCreated: "brand_created",
  campaignStarted: "campaign_started",
  campaignRequiredInformation: "campaign_required_information",
  campaignStrategySelected: "campaign_strategy_selected",
  campaignAssetPreview: "campaign_asset_preview",
  campaignGenerated: "campaign_generated",
  creativeDownloaded: "creative_downloaded",
  userSignedUp: "user_signed_up",
  userSignedIn: "user_signed_in",
  userSignedOut: "user_signed_out",
  userAuthTokenExpired: "user_auth_token_expired",
};
