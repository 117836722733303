"use client";

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "~/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Spinner } from "~/components/ui/spinner";
import { LoginCredentials } from "~/interfaces/auth";
interface Props {
  defaultValues?: LoginCredentials;
  onSubmit: (data: LoginCredentials) => void;
  error?: string;
}

const loginSchema = yup.object().shape({
  email: yup.string().email("Email address is invalid").required("Email is a required field"),
  password: yup.string().required("Password is a required field").min(8, "Password must be at least 8 characters"),
});

const schema = yupResolver(loginSchema);

export const LoginForm: React.FC<Props> = ({ onSubmit, defaultValues = { email: "", password: "" }, error }) => {
  const form = useForm<LoginCredentials>({
    resolver: schema,
    defaultValues,
  });
  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, isValid, submitCount, errors },
  } = form;

  useEffect(() => {
    setError("root.serverError", { message: error });
  }, [setError, error]);

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 max-w-90 mx-auto w-full desktop:max-w-80">
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input autoComplete="email" invalid={fieldState.invalid} placeholder="Your email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  autoComplete="current-password"
                  invalid={fieldState.invalid}
                  placeholder="Your password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormMessage>{errors.root?.serverError?.message}</FormMessage>

        <Button
          disabled={isSubmitting || (submitCount > 0 && !isValid)}
          variant="secondary"
          className="mt-2 max-w-60 mx-auto gap-2"
        >
          {isSubmitting ? <Spinner /> : "Login"}
        </Button>
      </form>
    </Form>
  );
};
